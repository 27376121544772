// extracted by mini-css-extract-plugin
export var accDiv = "privacy-policy-module--accDiv--dddb2";
export var blankLineP = "privacy-policy-module--blankLineP--da317";
export var captionB3 = "privacy-policy-module--captionB3--9287b";
export var captionDiv = "privacy-policy-module--captionDiv--2b015";
export var displayB = "privacy-policy-module--displayB--8a2cb";
export var displayDiv = "privacy-policy-module--displayDiv--9b53f";
export var docTemplateDiv = "privacy-policy-module--docTemplateDiv--e0296";
export var httpseceuropaeujusticed = "privacy-policy-module--httpseceuropaeujusticed--b2a7b";
export var httpseceuropaeujusticedSpan = "privacy-policy-module--httpseceuropaeujusticedSpan--6199f";
export var inShort = "privacy-policy-module--inShort--b62bb";
export var pDiv = "privacy-policy-module--pDiv--1d011";
export var pDiv1 = "privacy-policy-module--pDiv1--8b408";
export var pDiv14 = "privacy-policy-module--pDiv14--8c08a";
export var pDiv16 = "privacy-policy-module--pDiv16--f08ed";
export var pDiv32 = "privacy-policy-module--pDiv32--a2969";
export var privacyPolicyDiv = "privacy-policy-module--privacyPolicyDiv--9483e";
export var questionsOrConcerns = "privacy-policy-module--questionsOrConcerns--4aa1e";
export var readingThisPrivacyNoticeWi = "privacy-policy-module--readingThisPrivacyNoticeWi--3a5d3";
export var subtitleDiv = "privacy-policy-module--subtitleDiv--54449";
export var subtitleLargeDiv = "privacy-policy-module--subtitleLargeDiv--2639e";
export var textColumnDiv = "privacy-policy-module--textColumnDiv--88480";
export var textbodyDiv = "privacy-policy-module--textbodyDiv--3a1f0";
export var textbodyDiv12 = "privacy-policy-module--textbodyDiv12--7a8c4";
export var textbodyDiv13 = "privacy-policy-module--textbodyDiv13--75288";
export var textbodyDiv14 = "privacy-policy-module--textbodyDiv14--e77db";
export var textbodyDiv19 = "privacy-policy-module--textbodyDiv19--02bbf";
export var textbodyDiv2 = "privacy-policy-module--textbodyDiv2--6c977";
export var textbodyDiv23 = "privacy-policy-module--textbodyDiv23--7522f";
export var textbodyDiv35 = "privacy-policy-module--textbodyDiv35--bca6f";
export var textbodyDiv38 = "privacy-policy-module--textbodyDiv38--91079";
export var visitOurWebsite = "privacy-policy-module--visitOurWebsite--6839d";
export var visitOurWebsiteAtUdshealth = "privacy-policy-module--visitOurWebsiteAtUdshealth--24b3e";